import { default as billing0J1fBSlOuwMeta } from "/Users/geauser/code/misei/packages/app/pages/billing.vue?macro=true";
import { default as customsQz6mS1MG3QMeta } from "/Users/geauser/code/misei/packages/app/pages/customs.vue?macro=true";
import { default as indexgWA21HpDCXMeta } from "/Users/geauser/code/misei/packages/app/pages/index.vue?macro=true";
import { default as mintsxHuwCt0gQBMeta } from "/Users/geauser/code/misei/packages/app/pages/mints.vue?macro=true";
import { default as portfolioCyjNZDkHhSMeta } from "/Users/geauser/code/misei/packages/app/pages/portfolio.vue?macro=true";
import { default as terminusxmHnxOIje4Meta } from "/Users/geauser/code/misei/packages/app/pages/terminus.vue?macro=true";
import { default as waitlistuWwhjOhS3NMeta } from "/Users/geauser/code/misei/packages/app/pages/waitlist.vue?macro=true";
import { default as walletsyhBkBm3vvUMeta } from "/Users/geauser/code/misei/packages/app/pages/wallets.vue?macro=true";
export default [
  {
    name: "billing",
    path: "/billing",
    meta: billing0J1fBSlOuwMeta || {},
    component: () => import("/Users/geauser/code/misei/packages/app/pages/billing.vue")
  },
  {
    name: "customs",
    path: "/customs",
    meta: customsQz6mS1MG3QMeta || {},
    component: () => import("/Users/geauser/code/misei/packages/app/pages/customs.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgWA21HpDCXMeta || {},
    component: () => import("/Users/geauser/code/misei/packages/app/pages/index.vue")
  },
  {
    name: "mints",
    path: "/mints",
    meta: mintsxHuwCt0gQBMeta || {},
    component: () => import("/Users/geauser/code/misei/packages/app/pages/mints.vue")
  },
  {
    name: "portfolio",
    path: "/portfolio",
    meta: portfolioCyjNZDkHhSMeta || {},
    component: () => import("/Users/geauser/code/misei/packages/app/pages/portfolio.vue")
  },
  {
    name: "terminus",
    path: "/terminus",
    meta: terminusxmHnxOIje4Meta || {},
    component: () => import("/Users/geauser/code/misei/packages/app/pages/terminus.vue")
  },
  {
    name: "waitlist",
    path: "/waitlist",
    component: () => import("/Users/geauser/code/misei/packages/app/pages/waitlist.vue")
  },
  {
    name: "wallets",
    path: "/wallets",
    meta: walletsyhBkBm3vvUMeta || {},
    component: () => import("/Users/geauser/code/misei/packages/app/pages/wallets.vue")
  }
]