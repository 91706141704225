<script setup lang="ts">

</script>
<template>
  <ClientOnly>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UNotifications />

  </ClientOnly>
</template>
