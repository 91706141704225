export default defineAppConfig({

  ui: {
    primary: 'stone',
    gray: 'stone',

    popover: {
      ring: 'ring-gray-300 dark:ring-gray-700',
    },

    modal: {
      overlay: {
        base: 'fixed inset-0 transition-opacity',
        background: 'bg-gray-700/75 dark:bg-gray-800/75',
      },
      rounded: 'rounded-[4px]',
      shadow: 'shadow-md',
      width: 'w-full sm:max-w-lg',
    },

    tooltip: {
      background: 'dark:bg-gray-900 bg-white',
      color: 'text-gray-900 dark:text-white',
      ring: 'ring-0',
      base: `border border-gray-200 dark:border-gray-700 overflow-visible whitespace-normal h-auto py-1.5 px-2`,
      arrow: {
        ring: 'before:ring-0',
        background: 'dark:before:bg-white before:bg-gray-900',
      },
    },

    accordion: {
      wrapper: 'px-4',
      item: {
        base: 'px-4',
        size: 'text-sm',
        color: 'text-gray-500 dark:text-gray-300',
        padding: 'pt-1.5 pb-3',
        icon: 'ms-auto transform transition-transform duration-200',
      },
    },

    dropdown: {
      "container": "z-40",
      "rounded": "rounded-[4px]",
      padding: "p-0",
      "item": {
        "base": "group flex items-center gap-2 w-full",
        "rounded": "rounded-[4px]",
        "padding": "px-2 py-1.5",
        "size": "text-sm",
        "active": "bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white",
        "inactive": "text-gray-700 dark:text-gray-200",
        "disabled": "cursor-not-allowed opacity-50"
      },
    },


    table: {
      divide: "divide-y divide-gray-200 dark:divide-gray-700",
      thead: "bg-gray-50 dark:bg-gray-800",
      tbody: "bg-white dark:bg-gray-800 divide-y-0",
      tr: {
        base: 'hover:bg-gray-50/50 dark:hover:bg-gray-800/50 cursor-default',
        selected: 'bg-white dark:bg-gray-900',
        active: 'hover:bg-gray-100 dark:hover:bg-gray-800/50 cursor-default',
      },
      th: {
        padding: "px-3 py-1.5",
        font: "font-semibold",
        size: "text-xs"
      },
      td: {
        padding: "px-3 py-1.5",
        size: "text-xs"
      },
      checkbox: {
        padding: "ps-[18px]"
      },
    },

    card: {
      header: { padding: 'p-0' },
      footer: { padding: 'p-0' },
      divide: "",
    },

    notifications: {
      position: "bottom-auto top-0",
    },

    notification: {
      title: "font-semibold text-sm text-gray-900 dark:text-gray-200 mb-1",
      description: "!text-[13px] !leading-4",
      rounded: "rounded-[4px]",
      padding: 'px-4 py-3',
      progress: {
        base: "h-0"
      }
    },

    input: {
      base: 'disabled:shadow-none disabled:drop-shadow-none',
      rounded: 'rounded-[4px]',
      variant: {
        outline: 'bg-white dark:bg-gray-900'
      },
    },

    formGroup: {
      help: 'mt-1 text-xs text-gray-500 dark:text-gray-400',
      error: 'mt-1 text-xs text-red-600 dark:text-red-400',
      container: 'mt-0.5',
    },

    select: {
      rounded: 'rounded-[4px]'
    },

    selectMenu: {
      rounded: 'rounded-[4px]',
      padding: 'py-1.5 -px-1',
      option: {
        base: 'cursor-pointer',
        rounded: 'rounded-none',
      }
    },

    button: {
      base: `transition-all relative inline-flex justify-center disabled:opacity-50 disabled:!drop-shadow-none disabled:!shadow-none`,
      font: 'font-medium',
      rounded: 'rounded-[4px]',
      default: {
        color: 'black',
      },
      padding: {
        sm: 'py-1.5'
      },
      icon: {
        size: {
          xs: 'h-3.5 w-3.5',
          sm: 'h-4 w-4',
        },
      },
      color: {
        white: {
          solid: 'active:drop-shadow-none active:shadow-inner active:bg-gray-100 disabled:bg-gray-100'
        }
      },
    },

    buttonGroup: {
      rounded: 'rounded-md',
      orientation: {
        'rounded-md': { horizontal: { start: 'rounded-s-[4px]', end: 'rounded-e-[4px]' }, vertical: { start: 'rounded-t-[4px]', end: 'rounded-b-[4px]' } },
      }
    },

  },

});
